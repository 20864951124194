*{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.container{
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color:white;
  }

  @media (min-width: 768px) {
    .container {
    width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
    width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
    width: 1170px;
    }
  }
  .flex-container{
    display: flex;
    justify-content: space-between;
}
.heading {
  font-size: 3vw;
}
.titleLine{
  border-bottom: 2px solid  rgb(215, 77, 77);
  padding-bottom: 1%;
  padding-right:2%;
}
.subHeading{
  margin-top:0.5%;
  padding-top:0.7%;
  font-size:1.5vw;
}
.txt-sec{
  color:#ffffff9e;
}

.badge{
  display: inline-block;
  text-align: center;
  margin-left:0.5%;
  margin-right:3%; 
  border-radius:0.1vw;
  font-size:1.4vw;
  min-width: 12%;
}

.red-dot{
  margin-right: 5%;
  color:red;
}
.green-dot{
  margin-right: 5%;
  color:green;  
}
.gray-dot{
  margin-right: 5%;
  color:gray;  
}