table{
    border-collapse: collapse;
    width:100%; 
    text-align: left;
}
tr,th{
    border-bottom:1px solid #93919b;
}

th{
    font-size: 1.3vw;
    padding-left:1%;
}

td{
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left:1%;
    font-size:1.5vw;
    font-weight: 300;
}
td:last-child{
    width:30%;
}
td:nth-child(1),td:nth-child(3) {  
    font-weight: bolder;
    width: 15%;

  }